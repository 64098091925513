
import { Link } from "react-router-dom";
import '../../../../scss/shared/footer.scss';
import Logo from "../../../../assets/logo.png"
import WhLogo from "../../../../assets/icons/white_logo.svg"
import FB from "../../../../assets/icons/fb.png"
import WhFB from "../../../../assets/icons/fb-white.png"
import TW from "../../../../assets/icons/tw.png"
import WhTW from "../../../../assets/icons/tw-white.png"
import YT from "../../../../assets/icons/youtube.png"
import WhYT from "../../../../assets/icons/youtube-white.png"
import IG from "../../../../assets/icons/ig.png"
import WhIG from "../../../../assets/icons/ig-white.png"
import Mail from "../../../../assets/icons/mail.png"
import WhMail from "../../../../assets/icons/mail-white.png"
import LinkedIn from "../../../../assets/icons/linkedin.png"
import WhLinkedIn from "../../../../assets/icons/linkedin-white.png"
import PlayStore from "../../../../assets/icons/play_store.png"
import AppStore from "../../../../assets/icons/iosapp.png"

import { useWindowSize } from "../../../../hooks/useWindowSize";

export function Footer(props: any) {
    const screenResolution: any = useWindowSize();
    let { isStaticPage, greenFooter, isNewHomepage } = props;

    return (
        <div className={!isStaticPage ? `footer_wrap ${greenFooter ? "footer_shaded_green" : ""} ` : `footer_wrap ${greenFooter ? "footer_shaded_green" : "footer_shaded"}`}>
            {screenResolution?.width <= 768 &&
                <div className="mobile_footer_top">
                    <div className="logo_wrap">
                        <img alt="" src={greenFooter ? WhLogo : Logo} width={135} height={40} />

                    </div>
                    <div className="summary">
                        We&apos;re here to help you connect with customers across Canada by making it easy for them to find your store and buy your products. No matter where your business is located, we&apos;ll help shoppers discover you.



                    </div>

                    <div className="in_touch">

                        <div className="heading">
                            Get in touch
                        </div>
                        <div className={`social_links bottom_section_content`}>
                            <div className="each_icon">
                                <a href="https://twitter.com/mamimarkets" target="_blank">
                                    <img alt="" src={greenFooter ? WhTW : TW} />
                                </a>

                            </div>
                            <div className="each_icon">
                                <a href="https://www.facebook.com/mamimarkets" target="_blank">
                                    <img alt="" src={greenFooter ? WhFB : FB} />
                                </a>
                            </div>
                            <div className="each_icon">
                                <a href="https://www.youtube.com/channel/UCTFTrZ1wTQvosJREGHivy6A" target="_blank">
                                    <img alt="" src={greenFooter ? WhYT : YT} />
                                </a>
                            </div>
                            <div className="each_icon">
                                <a href="https://www.linkedin.com/company/mamimarkets-inc/" target="_blank">
                                    <img alt="" src={greenFooter ? WhLinkedIn : LinkedIn} />
                                </a>
                            </div>
                            <div className="each_icon">
                                <a href="https://www.instagram.com/mamimarkets/" target="_blank">
                                    <img alt="" src={greenFooter ? WhIG : IG} />
                                </a>
                            </div>

                            <div className="each_icon">
                                <a href="mailto:hello@mamimarkets.com" target="_blank">
                                    <img alt="" src={greenFooter ? WhMail : Mail} />
                                </a>

                            </div>
                            {/* <div className="each_icon">
                            <a href="https://www.youtube.com/channel/UCTFTrZ1wTQvosJREGHivy6A ">
                                <img alt="" src={Mail} />
                            </a>
                        </div> */}
                        </div>
                    </div>

                </div>
            }
            <div className="footer_navs">
                {screenResolution?.width > 768 &&
                    <div className="each_navlist_wrap">
                        <div className="logo_wrap">
                            <img alt="" src={greenFooter ? WhLogo : Logo} width={135} height={40} />

                        </div>
                        <div className="summary">
                            We&apos;re here to help you connect with customers across Canada by making it easy for them to find your store and buy your products. No matter where your business is located, we&apos;ll help shoppers discover you.

                        </div>
                        {screenResolution?.width >= 768 &&
                            <div className="in_touch">
                                <div className="heading">
                                    Get in touch
                                </div>

                                <div className={`social_links bottom_section_content`}>
                                    <div className="each_icon">
                                        <a href="https://twitter.com/mamimarkets" target="_blank" rel="noreferrer">
                                            <img alt="" src={greenFooter ? WhTW : TW} />
                                        </a>
                                    </div>
                                    <div className="each_icon">
                                        <a href="https://www.facebook.com/mamimarkets" target="_blank" rel="noreferrer">
                                            <img alt="" src={greenFooter ? WhFB : FB} />
                                        </a>
                                    </div>
                                    <div className="each_icon">
                                        <a href="https://www.youtube.com/channel/UCTFTrZ1wTQvosJREGHivy6A" target="_blank" rel="noreferrer">
                                            <img alt="" src={greenFooter ? WhYT : YT} />
                                        </a>
                                    </div>
                                    <div className="each_icon">
                                        <a href="https://www.linkedin.com/company/mamimarkets-inc/ " target="_blank" rel="noreferrer">
                                            <img alt="" src={greenFooter ? WhLinkedIn : LinkedIn} />
                                        </a>
                                    </div>

                                    <div className="each_icon">
                                        <a href="https://www.instagram.com/mamimarkets/" target="_blank" rel="noreferrer">
                                            <img alt="" src={greenFooter ? WhIG : IG} />
                                        </a>
                                    </div>

                                    <div className="each_icon">
                                        <a href="mailto:hello@mamimarkets.com" target="_blank" rel="noreferrer">
                                            <img alt="" src={greenFooter ? WhMail : Mail} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
                <div className="each_navlist_wrap">
                    <div className="nav_items">
                        <div className="nav_heading">Get to Know Us</div>
                        <a href="https://mamimarkets.com/careers" target="_blank">
                            Careers
                        </a>
                        {/* <a href="/blog">
                            <a> Blog</a>
                        </a> */}
                        {/* <a href="#">Blog</a> */}
                        <a href="https://mamimarkets.com/about-us" target="_blank">
                            About Us
                        </a>
                        <a href="https://mamimarkets.com/faq" target="_blank">
                            FAQs
                        </a>

                        <a href="https://mamimarkets.com/contact-us" target="_blank">
                            Contact Us
                        </a>
                        <a href="https://mamimarkets.com/help-center" target="_blank">
                            Help
                        </a>
                        {/* <a href="#">Help</a> */}
                        <a href="https://mamimarkets.com/privacy-policy" target="_blank">
                            Privacy Policy
                        </a>
                        <a href="https://mamimarkets.com/terms" target="_blank">
                            Terms &amp; Conditions
                        </a>
                        {/* <Link href="/press">
                            <a>Press Releases</a>
                        </Link> */}
                        {/* <a href="#">Press Releases</a> */}
                    </div>
                </div>
                <div className="each_navlist_wrap">
                    <div className="nav_items">
                        <div className="nav_heading">Top Cities</div>
                        {/* <Link href="/press"> */}
                        <a href="#">Winnipeg</a>
                        {/* </Link> */}
                        {/* <Link href="/#/"> */}
                        <a href="#">Toronto <span className="soon_txt"> Coming soon</span> </a>
                        {/* </Link> */}
                        {/* <Link href="/blog"> */}
                        <a href="#">Calgary <span className="soon_txt"> Coming soon</span></a>
                        {/* </Link> */}
                        {/* <Link href="/contact"> */}
                        <a href="#">Edmonton <span className="soon_txt"> Coming soon</span></a>
                        {/* </Link> */}
                        {/* <Link href="/help"> */}
                        <a href="#">Vancouver <span className="soon_txt"> Coming soon</span> </a>
                        {/* </Link> */}

                        {/* <Link href="/press"> */}
                        <a href="#">Halifax <span className="soon_txt"> Coming soon</span></a>
                        {/* </Link> */}
                        {/* <Link href="/press"> */}
                        <a href="#">Ottawa <span className="soon_txt"> Coming soon</span></a>
                        {/* </Link> */}
                        {/* <Link href="/press"> */}
                        {/* <a href="#">Montreal <span className="soon_txt"> Coming soon</span></a> */}
                        {/* </Link> */}
                    </div>
                </div>
                <div className="each_navlist_wrap">
                    <div className="nav_items">
                        <div className="nav_heading">Top Categories</div>
                        <a href="https://mamimarkets.com/top-categories/produce" target="_blank">
                            Produce
                        </a>
                        <a href="https://mamimarkets.com/top-categories/diary" target="_blank">
                            Diary &amp; Eggs
                        </a>
                        <a href="https://mamimarkets.com/top-categories/diary" target="_blank">
                            Vegetables
                        </a>
                        <a href="https://mamimarkets.com/top-categories/beverage" target="_blank">
                            Beverages
                        </a>
                        <a href="https://mamimarkets.com/top-categories/beauty" target="_blank">
                            Beauty
                        </a>
                        <a href="https://mamimarkets.com/top-categories/meat" target="_blank">
                            Meats
                        </a>
                        <a href="https://mamimarkets.com/top-categories/bikes" target="_blank">
                            Bikes
                        </a>
                    </div>
                </div>
                <div className="each_navlist_wrap">
                    <div className="nav_items">
                        <div className="nav_heading">Become a Seller</div>
                        <a href="#steps-join">
                            How to List Your Shop
                        </a>
                        <a href="#why-choose">
                            Benefits of Selling on MamiMarkets
                        </a>
                        <a href="/get-started" target="_blank">
                            Start Selling on MamiMarkets
                        </a>
                        {/* {screenResolution?.width >= 769 &&
                            <div className="store_urls">
                                <div className="each_store">
                                    <a href="https://apps.apple.com/ca/app/mamimarkets/id1672468988" target="_blank">
                                        <img alt="" src={AppStore} width={100} />
                                    </a>

                                </div>
                                <div className="each_store">
                                    <a href="https://play.google.com/store/apps/details?id=com.mamimarkets.app" target="_blank">
                                        <img alt="" src={PlayStore} width={100} />
                                    </a>

                                </div>
                            </div>
                        } */}
                    </div>
                </div>
            </div>

            {/* <div className={styles.subsribe_box}>
                <div className={styles.sub_heading}>Be the first to know about our promotions and offers</div>
                <div className={styles.input_wrap}>
                    <input type="text" placeholder="Email" />
                    <button type="button" className={`btn ${styles.subscribe_btn}`}>Subscribe</button>
                </div>
            </div> */}
            {/* <div className={styles.page_bottom}> */}
            {/* <div className={styles.page_bottom_section}>
                    <div className={styles.bottom_section_heading}>Contact</div>
                    <div className={styles.bottom_section_content}>
                        <a href="mailto:hello@mamimarkets.com">hello@mamimarkets.com</a>

                    </div>
                    <div className={`social_links ${styles.bottom_section_content}`}>
                        <div className="each_icon">
                            <a href="#">
                                <img alt="" src={FB} />
                            </a>
                        </div>
                        <div className="each_icon">
                            <a href="https://www.instagram.com/mamimarkets/ ">
                                <img alt="" src={IG} />
                            </a>
                        </div>
                        
                        <div className="each_icon">
                            <a href="https://www.youtube.com/channel/UCTFTrZ1wTQvosJREGHivy6A ">
                                <img alt="" src={YT} />
                            </a>
                        </div>
                    </div>
                </div> */}

            {/* <div className={styles.page_bottom_section}>
                    <div className={styles.each_footlink}>
                        <Link href="/legal">
                            <a>Legal</a>
                        </Link>
                    </div>
                    <div className={styles.each_footlink}>
                        <Link href="/policy">
                            <a>Privacy Policy</a>
                        </Link>
                    </div>
                    <div className={styles.each_footlink}>
                        <Link href="/terms">
                            <a>Terms and Conditions</a>
                        </Link>
                    </div>
                </div> */}
            {/* </div> */}
        </div>
    );
}
