import React from "react";
import { Link } from "react-router-dom";
import 'react-multi-carousel/lib/styles.css';
import Carousel from 'react-multi-carousel';
import { useWindowSize } from "../../../hooks/useWindowSize";
import logo from "../../../assets/logo.png"
import '../../../scss/new-landing.scss'
import mumbo from '../../../assets/icons/mumbo.svg'
import rubik from '../../../assets/icons/rubik.svg'
import toast from '../../../assets/icons/toast.svg'
import gusto from '../../../assets/icons/gusto.svg'
import pendo from '../../../assets/icons/pendo.svg'
import synk from '../../../assets/icons/synk.svg'


import card1 from '../../../assets/card1.svg'
import card2 from '../../../assets/card2.svg'
import card3 from '../../../assets/card3.svg'
import card4 from '../../../assets/card4.svg'
import card5 from '../../../assets/card5.svg'

// import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';

import Hero1 from '../../../assets/landing/hero-1.webp'
import Hero1m from '../../../assets/landing/hero-1-m.webp'
import Hero2 from '../../../assets/landing/hero-2.webp'
import Hero3 from '../../../assets/landing/hero-3.webp'
import Why1 from '../../../assets/landing/why-1.png'
import Why2 from '../../../assets/landing/why-2.png'
import Why3 from '../../../assets/landing/why-3.png'

import Step1 from '../../../assets/landing/step-1.png'
import Step2 from '../../../assets/landing/step-2.png'
import Step3 from '../../../assets/landing/step-3.png'
import Step4 from '../../../assets/landing/step-4.png'
import Step5 from '../../../assets/landing/step-5.png'
import How from '../../../assets/landing/how.webp'
import { Cards } from "./cardsV2";
import { Footer } from "./footer";





const cards = [
    {
        icon: Step1,
        name: 'Create a free seller account',
        text: 'Sign up with your store and personal information.',
        bg: card1
    },
    {
        icon: Step2,
        name: 'Verify your account',
        text: 'Click on the verification link sent to the email address you provided at signup.',
        bg: card2
    },
    {
        icon: Step3,
        name: 'Add a bank account',
        text: 'Add a bank account to your vendor dashboard for receiving payments.',
        bg: card3
    },
    {
        icon: Step4,
        name: 'Add products to your store',
        text: 'Add products to your store on MamiMarkets.',
        bg: card4
    },
    {
        icon: Step5,
        name: 'Start selling',
        text: 'Start selling on MamiMarkets.',
        bg: card5
    },

]
const whyItems = [
    {
        icon: Why1,
        heading: "Boost your visibility",
        whyDetails: "Pick a convenient time for you. Enjoy 100% quality guarantee on every order."
    },
    {
        icon: Why2,
        heading: "$0 Sign-up/Commission",
        whyDetails: "No setup fees, commission, or hidden charges."
    },
    {
        icon: Why3,
        heading: "24/7 Support",
        whyDetails: "We only partner with top retailers that offer the finest products."
    }
]

export interface WhyItem {
    icon: string,
    heading: string,
    whyDetails: string,
}

const HeroSection = () => {
    const screenResolution: any = useWindowSize();
    return (
        <div className="hero-section">
            <div className="hero-content">
                <div className="side-left">
                    <h1 className="main-heading">Grow your business {screenResolution?.width >= 425 && <br />}with MamiMarkets</h1>
                    <h3 className="sub-text">Create a digital storefront on MamiMarkets to reach new customers, increase order volume, and drive more sales.</h3>
                    <div className="start-cta">
                        <Link className="btn" to="/get-started"> Start for free</Link>
                    </div>
                </div>
                <div className="side-right">
                    <div className="main-img">
                        <img loading="lazy" src={screenResolution?.width >= 1025 ? Hero1 : Hero1m} alt="" />
                    </div>
                    <div className="other-imgs">
                        <div className="img-2">
                            <img loading="lazy" src={Hero2} alt="" />
                        </div>
                        <div className="img-3">
                            <img loading="lazy" src={Hero3} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const WhyChoose = () => {

    return (
        <div className="why-choose" id="why-choose">
            <div className="why-heading">Why MamiMarkets?</div>
            <div className="why-items">
                {
                    whyItems.map((eachItem: WhyItem, index) => {
                        return (
                            <div className="each-why-item" key={index}>
                                <div className="item-icon">
                                    <img loading="lazy" src={eachItem.icon} alt="" />
                                </div>
                                <div className="item-heading">
                                    <h2>{eachItem.heading}</h2>
                                </div>
                                <div className="item-details">{eachItem.whyDetails} </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
const HowWorks = () => {

    return (
        <div className="how-works" >
            <div className="left-side">
                <div className="heading-text"> <h2>How MamiMarkets <br /> Works for Sellers</h2></div>
                <div className="other-text">We help sellers in reach and sell to more customers.<br /> Watch our intro video to learn more</div>
                <div className="get-started-cta">
                    <Link className="btn" to="/get-started"> Create your store</Link>
                </div>
            </div>
            <div className="right-side">
                <img loading="lazy" src={How} alt="" />
                {/* <LazyLoadImage
    //   alt={image.alt}
    //   height={image.height}
    effect="blur"
      src={How} // use normal <img> attributes as props
    //   width={image.width} 
      /> */}
            </div>
        </div>
    )
}

const ControllerButtons = ({ next, previous, goToSlide, ...rest }: any) => {
    const { carouselState: { currentSlide } } = rest;

    return (
        <React.Fragment>
            <div className="custom_controller_wrap">
                <button className={currentSlide === 0 ? 'disabled_prev_btn' : 'prev_btn'} onClick={() => previous()}> </button>
                <button className="next_btn" onClick={() => next()}></button>
            </div>
        </React.Fragment>
    )
}
const LandingPage = () => {
    const screenResolution: any = useWindowSize();
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 6
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 6,
            // partialVisibilityGutter: 40
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            partialVisibilityGutter: 20,
            items: 2
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1
        }
    };

    return (
        <div className="containers2">
            <div className="landing-header">
                <div className="logo">
                    <img src={logo} alt="" />
                </div>
                <div className="links">
                    <Link to={'/login'} className="login">Log in </Link>
                    <a href='#form' className="get-button">Get started</a>
                </div>
            </div>
            <HeroSection />
            <WhyChoose />
            <HowWorks />


            <div className="graphbg" id="steps-join">
                <div className="steps-wrap">
                    <div className="first">
                        <h3>Get Started in 5 Simple Steps</h3>
                        <p>Start selling on MamiMarkets in five simple steps.</p>
                    </div>
                    <div className="first2">
                        {screenResolution?.width >= 1025 &&
                            <div className="grid">
                                {
                                    cards.map((elem, idx) => {
                                        return (
                                            <div key={idx} className={`cardBg`}>
                                            {/* <div key={idx} className={`cardBg`} style={{ background: `url(${elem.bg})`, backgroundPosition: 'center', backgroundSize: '100%' }}> */}
                                                <div className="bg-text">{idx+1}</div>
                                                <div className="wrap-content">
                                                    <div className="icon"><img width="100%" src={elem.icon} alt="" /> </div>
                                                    {/* <div className="icon">{elem.icon}</div> */}
                                                    <h4 className="cardTitle">{elem.name}</h4>
                                                    <p className="cardtext">{elem.text}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                        {screenResolution?.width <= 1024 &&
                            <Carousel
                                responsive={responsive}
                                arrows={false}
                                renderArrowsWhenDisabled={true}
                                renderButtonGroupOutside={true}
                                customButtonGroup={<ControllerButtons />}
                                // customLeftArrow={<></>}
                                // customRightArrow={<></>}
                                swipeable={true}
                                rewind={true}
                                // autoPlay={true}

                                // minimumTouchDrag={20}
                                partialVisible={true}
                                keyBoardControl={true}
                                containerClass="how_slide"
                                itemClass="how_slide_item"
                                >
                                    {
                                        cards.map((elem, idx) => {
                                            return (
                                                // <div key={idx} className={`cardBg`} style={{ background: `url(${elem.bg})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
                                                <div key={idx} className={`cardBg`}>
                                                    <div className="bg-text">{idx+1}</div>
                                                    <div className="wrap-content">
                                                        <div className="icon"><img width="100%" src={elem.icon} alt="" /> </div>
                                                        {/* <div className="icon">{elem.icon}</div> */}
                                                        <h4 className="cardTitle">{elem.name}</h4>
                                                        <p className="cardtext">{elem.text}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                            </Carousel>
                        }

                    </div>
                </div>
            </div>
            <Cards />
            <Footer />
        </div>
    )
}
export default LandingPage;