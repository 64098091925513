import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import "../../../../scss/new-landing.scss";
import { Link } from "react-router-dom";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../../../../redux/actions/onboarding";
import { store } from "../../../../redux/store";

import { appConstants } from "../../../../redux/action-constants/inapp.constants";

import { connect } from "react-redux";
import Shop from "../../../../assets/icons/store-logo.svg";
import { AlertMsg } from "../../../../shared/components/alert-msg";
import { useNavigate } from "react-router-dom";




const validationSchemaStep1 = yup.object({
  username: yup.string().email("Valid email required").required("Store email is required")
});





const forgotPasswordAction = async ({ pageProps, payload }: any) => {

  await pageProps.ForgotPassword(payload);
}





const ForgotPwForm = (pageProps: any) => {
  const [formIndex, setFormIndex] = useState<number>(1);
  const [emailTxt, setEmailTxt] = useState<string>("")
  const [showPassword, setShowPassword] = useState(false);
  const app_state = useSelector(
    (state: any) => state?.onboardingOutboundReducers
  );
  const dispatch = useDispatch();


  useEffect(() => {


    // pageProps.logoutAction();
    forgotPasswordAction({
      pageProps,
      payload: "CLEAR"
    })




  }, [])



  const {
    handleBlur,
    handleChange,
    errors,
    touched,
    handleSubmit,
    values,
    setTouched,
    validateForm,
    validateField,
  }: any = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: validationSchemaStep1,
    onSubmit: async (values) => {

      let { username } = values;
      let payload: any = {
        email: username
      }
      await forgotPasswordAction({ pageProps, payload })
    },
  });







  const handleFieldChange = async (e: any) => {
    const { name } = e.target;
    handleChange(e);

    // Mark field as touched when it changes
    setTouched({ ...touched, [name]: true });

    // Validate the field immediately
    await validateField(name);
  };

  const handleFieldBlur = async (e: any) => {
    const { name } = e.target;
    handleBlur(e);

    // Mark field as touched when it blurs
    setTouched({ ...touched, [name]: true });

    // Validate the field immediately
    await validateField(name);

  };














  return (
    <div className="create-form" id="signup-form">

      <div className="signup-wrap">
        <div className="flex">
          <h4 className="formTitle">Forgot your Password?</h4>

        </div>
        <p className="text more-desc" style={{ marginTop: '.2rem' }}>Let's help you recover it
        </p>
        {(pageProps?.forgotPasswordRequest?.request_status
          && pageProps.forgotPasswordRequest.request_status !== "FORGOT_PASSWORD_SUCCESS") &&
          <div className="initial-fields">

            {pageProps?.forgotPasswordRequest?.request_status
              && pageProps.forgotPasswordRequest.request_status === "FORGOT_PASSWORD_FAILURE" &&
              <AlertMsg type="error" message={pageProps.forgotPasswordRequest?.request_data.error} />
            }
            <div className="inputbox2">
              <label htmlFor="" className="label">Email</label>
              <input
                type="email"
                name="username"
                onChange={handleFieldChange}
                onBlur={handleFieldBlur}
                placeholder="Email address"
                value={values.username}
                className={`input-wrap email-txt inapp-input ${errors.username && touched.username ?
                  "is-invalid-input "
                  : !errors.username && values.username !== "" ? "valid-input" : ""}`}
              />
              {!!errors.username && touched.username && (
                <p className="error">{errors.username}</p>
              )}
            </div>

            <button
              disabled={pageProps.forgotPasswordRequest?.is_request_processing}
              onClick={handleSubmit} type="submit" className="continue full">
              {pageProps.forgotPasswordRequest.is_request_processing ? "Please wait..." : "Continue"}
            </button>

            <div className="have-account">
              <Link to="/log-in" className="terms-link"> Back to Login</Link>
            </div>

          </div>
        }

        {(pageProps?.forgotPasswordRequest?.request_status
          && pageProps.forgotPasswordRequest.request_status === "FORGOT_PASSWORD_SUCCESS") &&
          <div className="success-message-wrap">
            <div className="success-animation">
              <svg className="success_icon_tick" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
            </div>
            <div className="success-message-txt">Please click the link sent to your email to reset your password</div>

          </div>
        }



      </div>


    </div>
  );
};

const mapDispatchToProps = {
  ForgotPassword: authActions.ForgotPassword,
  logoutAction: authActions.Logout,
};

const mapStateToProps = (state: any) => ({
  forgotPasswordRequest: state.onboardingOutboundReducers.forgotPasswordReducer,
});
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPwForm);
