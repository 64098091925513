import { useState, useEffect } from "react";
import '../../../scss/onboarding.scss';

import { connect } from 'react-redux';
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from "react-router-dom";
import { OnBoardingFormValues, ILoginProps } from "../../../types"
import GSign from "../../../assets/g-sign.svg"
import PwSeen from "../../../assets/icons/pw-visible.png"
import PwHide from "../../../assets/icons/pw-hidden.png"
import { PostBoardingWrap } from "../../../shared/templates/onboarding";
import { authActions } from "../../../redux/actions/onboarding"
import { AlertMsg } from "../../../shared/components/alert-msg"
import ForgotPwForm from "./form/forgot-password";
import { useWindowSize } from "../../../hooks/useWindowSize";

const PostBoardingContent = ({ pageProps, setIsOtherSteps }: any) => {
   

    return (
        <div className="postboarding-content postboarding-content-2">
            <div className="containers3">

                <ForgotPwForm  />
            </div>
            

        </div>
    )
}


const NewForgotPw = (pageProps: any) => {
    const [isOtherSteps, setIsOtherSteps] = useState<boolean>(false)
    return (
        <PostBoardingWrap isV2={true} isOtherSteps={isOtherSteps} innerWrapper="inner-wrapper" className="v2-bg" pageHeadingTitle="Sign in to your Account" childComponent={<PostBoardingContent setIsOtherSteps={setIsOtherSteps} pageProps={pageProps} />} />
    )
}


const mapDispatchToProps = {
    LoginAction: authActions.Login,
    logoutAction: authActions.Logout,
    resendActivationEmailAction: authActions.ResendActivationEmail,
};

const mapStateToProps = (state: any) => ({
    loginRequest: state.onboardingOutboundReducers.loginReducer,
    resendActivationEmailRequest: state.onboardingOutboundReducers.resendActivationEmailReducer,
})
export default connect(mapStateToProps, mapDispatchToProps)(NewForgotPw);