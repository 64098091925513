import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import "../../../../scss/new-landing.scss";
import { Link } from "react-router-dom";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../../../../redux/actions/onboarding";
import { store } from "../../../../redux/store";

import { appConstants } from "../../../../redux/action-constants/inapp.constants";

import { connect } from "react-redux";
import Shop from "../../../../assets/icons/store-logo.svg";
import { AlertMsg } from "../../../../shared/components/alert-msg";
import { useNavigate } from "react-router-dom";

import { useWindowSize } from "../../../../hooks/useWindowSize";


const validationSchemaStep1 = yup.object({
  username: yup.string().email("Valid email required").required("Store email is required"),
  password: yup.string().required("Password is required"),
});


const handleLogin = async ({ pageProps, payload }: any) => {

  await pageProps.LoginAction(payload);
}

const handleresendActivation = async ({ pageProps, payload }: any) => {

  await pageProps.resendActivationEmailAction(payload);
}





const LoginForm = (pageProps: any) => {
  const [formIndex, setFormIndex] = useState<number>(1);
  const [emailTxt, setEmailTxt] = useState<string>("")
  const [showPassword, setShowPassword] = useState(false);
  const app_state = useSelector(
    (state: any) => state?.onboardingOutboundReducers
  );
  const dispatch = useDispatch();


  useEffect(() => {


    // pageProps.logoutAction();
    handleLogin({
      pageProps,
      payload: "CLEAR"
    })

    handleresendActivation({
      pageProps,
      payload: "CLEAR"
    })


  }, [])



  const {
    handleBlur,
    handleChange,
    errors,
    touched,
    handleSubmit,
    values,
    setTouched,
    validateForm,
    validateField,
  }: any = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: validationSchemaStep1,
    onSubmit: async (values) => {

      let { username, password } = values;
      let payload: any = {
        username,
        password,
        grant_type: "password",
        scope: "api1.read IdentityServerApi",
        client_secret: "SuperSecretPassword",
        // client_secret: process.env.REACT_APP_IDENTTITY_SECRET,
        client_id: "oauthClient"
      }
      setEmailTxt(username)
      var formBody: any = [];
      for (var property in payload) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(payload[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      // setLoginData(formBody)
      await handleLogin({ pageProps, payload: formBody })
    },
  });







  const handleFieldChange = async (e: any) => {
    const { name } = e.target;
    handleChange(e);

    // Mark field as touched when it changes
    setTouched({ ...touched, [name]: true });

    // Validate the field immediately
    await validateField(name);
  };

  const handleFieldBlur = async (e: any) => {
    const { name } = e.target;
    handleBlur(e);

    // Mark field as touched when it blurs
    setTouched({ ...touched, [name]: true });

    // Validate the field immediately
    await validateField(name);

  };














  return (
    <div className="create-form" id="signup-form">

      <div className="signup-wrap">
        <div className="flex">
          <h4 className="formTitle">Sign In to your MamiMarkets account</h4>

        </div>
        <p className="text more-desc" style={{ marginTop: '.2rem' }}>Manage your products, track sales, and stay connected with your customers
        </p>

        <div className="initial-fields">
          <div className="inputbox2">
            <label htmlFor="" className="label">Email</label>
            <input
              type="email"
              name="username"
              onChange={handleFieldChange}
              onBlur={handleFieldBlur}
              placeholder="Email address"
              value={values.username}
              className={`input-wrap email-txt inapp-input ${errors.username && touched.username ?
                "is-invalid-input "
                : !errors.username && values.username !== "" ? "valid-input" : ""}`}
            />
            {!!errors.username && touched.username && (
              <p className="error">{errors.username}</p>
            )}
          </div>
          <div className="inputbox2">
            <label htmlFor="" className="label">Password</label>
            <input
              name="password"
              onChange={handleFieldChange}
              onBlur={handleFieldBlur}
              value={values.password}
              type={!showPassword ? "password" : "text"}
              placeholder="Enter your password"
              className={`input-wrap inapp-input ${errors.password && touched.password ?
                "is-invalid-input "
                : !errors.password && values.password !== "" ? "valid-input" : ""}`}
            />
            <div onClick={() => setShowPassword((prev) => !prev)}>
              {!showPassword ? (
                <AiFillEye className="eye" />
              ) : (
                <AiFillEyeInvisible className="eye" />
              )}
            </div>
            {!!errors.password && touched.password && (
              <p className="error">{errors.password}</p>
            )}
          </div>

          <button
            disabled={pageProps.loginRequest?.is_request_processing}
            onClick={handleSubmit} type="submit" className="continue full">
            {pageProps.loginRequest.is_request_processing ? "Please wait..." : "Log in"}
          </button>

          <div className="have-account">Forgot your password?
            <Link to="/forgot-password" className="terms-link"> Reset here</Link>
          </div>

          <div className="have-account">Don't have an account?
            <Link to="/get-started" className="terms-link"> Get Started</Link>
          </div>

        </div>
        {pageProps?.loginRequest?.request_status
          && pageProps.loginRequest.request_status === "LOGIN_USER_FAILURE" &&
          <>
            {pageProps?.resendActivationEmailRequest?.request_status === "RESEND_ACTIVATION_EMAIL_RESET" &&
              <AlertMsg type="error" message={pageProps.loginRequest?.request_data.error} />
            }
            {pageProps?.resendActivationEmailRequest?.request_status === "RESEND_ACTIVATION_EMAIL_FAILURE" &&
              <AlertMsg type="error" message={pageProps.resendActivationEmailRequest?.request_data.error} />
            }
            {pageProps?.resendActivationEmailRequest?.request_status === "RESEND_ACTIVATION_EMAIL_SUCCESS" &&
              <AlertMsg type="success" message="Activation email has been resent to you" />
            }

            {(pageProps.loginRequest?.request_data.error.includes("not yet activated") && pageProps?.resendActivationEmailRequest?.request_status !== "RESEND_ACTIVATION_EMAIL_SUCCESS") &&
              <div className="form-link resend-link"
                onClick={async () => {
                  await handleresendActivation({ pageProps, payload: `${emailTxt}ll` })
                }}
              >
                Click here to resend activation email
                {/* <Link to="/account/forgot-password"></Link> */}
              </div>

            }
          </>
        }


      </div>


    </div>
  );
};

const mapDispatchToProps = {
  LoginAction: authActions.Login,
  logoutAction: authActions.Logout,
  resendActivationEmailAction: authActions.ResendActivationEmail,
};

const mapStateToProps = (state: any) => ({
  loginRequest: state.onboardingOutboundReducers.loginReducer,
  resendActivationEmailRequest: state.onboardingOutboundReducers.resendActivationEmailReducer,
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
