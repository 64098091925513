import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import "../../../../scss/new-landing.scss";
import { Link } from "react-router-dom";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { MultiSelect } from "react-multi-select-component";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../../../../redux/actions/onboarding";
import { store } from "../../../../redux/store";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { appConstants } from "../../../../redux/action-constants/inapp.constants";
import PhoneInput from "react-phone-input-2";
import { connect } from "react-redux";
import Shop from "../../../../assets/icons/store-logo.svg";
import { AlertMsg } from "../../../../shared/components/alert-msg";
import { useNavigate } from "react-router-dom";
import { async } from "q";
import { useWindowSize } from "../../../../hooks/useWindowSize";

const validationSchema3: any = yup.object().shape({
  // storeCats: yup
  //   .array()
  //   .of(
  //     yup.object({
  //       value: yup.string().required("Required"),
  //       label: yup.string().required("Required"),
  //     })
  //   )
  //   .required("At least one item is required"),
  // storeAddress: yup.string().required("Address is required"),
  // storeProvince: yup.string().required("Store Provice is required"),
  // storePostCode: yup.string().optional(),
  // phoneNumber: yup.string().required("Phone number is required"),


  // storeName: yup.string().required("Required"),
  // storeUrl: yup.string().required("Store url is required"),

  //personal information

  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  ownerPhone: yup.string().required("Required"),
  ownerPostCode: yup.string().required("Required"),
  ownerCity: yup.string().required("Required"),
  ownerAddress: yup.string().required("Required"),
  howHear: yup.string().required("Required"),
  acceptTerms: yup.boolean().oneOf([true], 'You must accept the terms and conditions').required('Accepting terms is required'),
});

const validationSchemaStep1 = yup.object({
  storeEmail: yup.string().email("Valid email required").required("Store email is required"),
  password: yup.string().required("Password is required"),
});
const validationSchemaStep2 = yup.object().shape({
  storeCats: yup
    .array()
    .of(
      yup.object({
        value: yup.string().required("Required"),
        label: yup.string().required("Required"),
      })
    )
    .required("At least one item is required")
    .min(1, 'You must provide at least one category'),
  storeAddress: yup.string().required("Address is required"),
  storeProvince: yup.string().required("Store Provice is required"),
  storePostCode: yup.string().required("Postal Code is required"),
  phoneNumber: yup.string().required("Phone number is required"),


  storeName: yup.string().required("Required"),
  storeUrl: yup.string().required("Store url is required"),
});

// const validationSchemaStep2 = yup.object({
//   storeEmail: yup.string().email().required("Store email is required"),
//   password: yup.string().required("Password is required"),
// });

const getStoreUrlAction = async ({ pageProps, payload }: any) => {

  await pageProps.getStoreUrlAction(payload);
}

const getStoreCatsAction = async ({ pageProps, payload }: any) => {

  await pageProps.getStoreCategoriesAction(payload);
}

const handleSignup = async ({ pageProps, payload, storeLogo }: any) => {

  await pageProps.createAccountAction(payload, storeLogo)
}

const PersonalInfo = (pageProps: any) => {
  const [formIndex, setFormIndex] = useState<number>(1);
  const app_state = useSelector(
    (state: any) => state?.onboardingOutboundReducers
  );
  const dispatch = useDispatch();
  const [addressSearched, setAddressSearched] = useState<any>();
  const [addressLatLng, setAddressLatLng] = useState<any>();
  const [ownerExtractedInfo, setOwnerExtractedInfo] = useState<any>();
  const [ownerAddress, setOwnerAddress] = useState<any>(null);
  const [ownerAddressSearched, setOwnerAddressSearched] = useState<any>();
  const [storeLogo, setStoreLogo] = useState<any>({ image: Shop });
  const [isStoreLogoNew, setStoreLogoNew] = useState(false);
  const navigation = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [storeLogoConverted, setStoreLogoConverted] = useState<any>();

  const [extractedInfo, setExtractedInfo] = useState<any>();
  const [stepValidities, setStepValidities] = useState([false, false]);
  const [isStepTwoValid, setIsStepTwoValid] = useState(false);
  const [isStepThreeValid, setIsStepThreeValid] = useState(false);

  const [touchedFields, setTouchedFields] = useState<any>({});
  const { setIsOtherSteps } = pageProps
  const screenResolution: any = useWindowSize();
  useEffect(() => {

    handleSignup({
      pageProps,
      payload: "CLEAR"
    })
    getStoreUrlAction({
      pageProps,
      payload: "CLEAR"
    })




  }, [])
  const dataURLtoFile = (dataurl: any, filename: any) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };




  const todataurl = async (url: any) => {
    fetch(url)
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
      }))
  }

  const genrateDefaultLogo = async (image: any, filename: string) => {


    let getFileData = await fetch(image)
      .then(async response => {
        const contentType = response.headers.get('content-type')
        const blob = await response.blob()
        // @ts-ignore
        const file = new File([blob], filename, { contentType })


        return { file };
      })
    return getFileData

  }
  const handleLogoUpload = (e: any) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setStoreLogo({ file, image: reader.result });
      setStoreLogoNew(true);
    };

    reader.readAsDataURL(file);
  };
  let validateScheme: any;

  if (formIndex === 1) {
    validateScheme = validationSchemaStep1;
  }

  if (formIndex === 2) {
    validateScheme = validationSchemaStep2;
  }

  if (formIndex === 3) {
    validateScheme = validationSchema3;
  }



  let extractedData: any = {};
  const {
    handleBlur,
    handleChange,
    errors,
    setFieldValue,
    touched,
    handleSubmit,
    values,
    setTouched,
    setFieldTouched,
    setFieldError,
    isValid,
    validateForm,
    validateField,
    setErrors,
  }: any = useFormik({
    initialValues: {
      storeCats: [],
      storeProvince: "",
      storeAddress: "",
      storePostCode: "",
      phoneNumber: "",
      storeEmail: "",
      password: "",
      storeName: "",
      storeUrl: "",
      firstName: "",
      lastName: "",
      ownerPhone: "",
      ownerPostCode: "",
      ownerCity: "",
      ownerAddress: "",
      howHear: "",
      acceptTerms: false
    },
    validationSchema: validateScheme,
    onSubmit: async (values) => {


      if (storeLogo) {
        let {
          storeEmail,
          password,
          storeName,
          storeUrl,
          phoneNumber,
          storeProvince,
          storePostCode,
          firstName,
          lastName,
          ownerPhone,
          ownerPostCode,
          howHear,
        } = values;
        let payload = {
          email: storeEmail,
          password,
          storeModel: {
            name: storeName,
            url: storeUrl,
            phoneNumber,
            address: {
              city: extractedInfo?.provinceChosenFull,
              postalCode: storePostCode || extractedInfo?.postCode,
              streetAddress: extractedInfo?.newAddress,
              country: "Canada",
              countryId: 0,
            },
            tags: values.storeCats.map((ele: { value: string }) => ele?.value),
            email: storeEmail,
            longitude: addressLatLng.lng,
            latitude: addressLatLng.lat,
          },
          userProfileModel: {
            firstName,
            lastName,
            phoneNumber: ownerPhone,
            postalCode: ownerPostCode || ownerExtractedInfo?.postCode,
            streetAddress: ownerExtractedInfo?.newAddress,
            city: ownerExtractedInfo?.provinceChosenFull,
            country: "Canada",
            howDidYouHearAboutUs: howHear,
          },
        };


        let logoFormData: any = new FormData();

        if (!storeLogo?.file) {


          let logdataURLtoFile = genrateDefaultLogo(storeLogo?.image, `${storeName}-logo.png`);

          genrateDefaultLogo(storeLogo?.image, `${storeName}-logo.png`)
            .then((res: any) => {


              logoFormData.append("logo", res?.file);


              setStoreLogoConverted(logoFormData)

            })


          // logoFormData.append("logo", logdataURLtoFile);
        } else {
          logoFormData.append("logo", storeLogo?.file);
          setStoreLogoConverted(logoFormData)
        }

        if (storeLogoConverted) {


          try {

            await handleSignup({ pageProps, payload, storeLogo: storeLogoConverted })
            // await pageProps.createAccountAction(payload, logoFormData);
          } catch (e) {


          }
        }
      }
    },
  });

  //categories
  const [categories, setCategories] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const [isComponentMounted, setIsComponentMounted] = useState(false);

  const handleNext = (id: number) => {
    const form_1 = {
      storeName: values.storeName,
      storeUrl: values.storeUrl,
      storeCats: values.storeCats,
      storeAddress: values.storeAddress,
      storePostCode: values.storePostCode,
      storeProvince: values.storeProvince,
      phoneNumber: values.phoneNumber,
      storeEmail: values.storeEmail,
      password: values.password
    };

    if (Object.values(form_1).some((ele) => !Boolean(ele))) {
      Object.keys(form_1).map((element) => setFieldTouched(element, true));
    } else {
      const doc = document.querySelector(`.line_${id}`);
      doc?.classList.add("line_bg_full");
      setFormIndex(2);
    }


  };
  useEffect(() => {
    let signUpElem = document.getElementById("signup-form") as HTMLDivElement;
    signUpElem.scrollIntoView({ behavior: "smooth", block: "start" });
  }, [formIndex])




  useEffect(() => {
    if (!isComponentMounted) {
      setIsComponentMounted(true);
      setCategories([]);
    } else if (
      Array.isArray(
        app_state?.getStoreCatsReducer?.request_data?.response?.results
      )
    ) {
      const all_cats =
        app_state?.getStoreCatsReducer?.request_data?.response?.results?.map(
          (element: { name: string }) => {
            return {
              value: element?.name,
              label: element?.name,
            };
          }
        );
      setCategories(all_cats);
    }
  }, [app_state, isComponentMounted]);
  useEffect(() => {
    pageProps.getStoreCategoriesAction(`Page=1&Size=1000`);
  }, []);
  useEffect(() => {
    if (extractedInfo) {


      setFieldValue("storeProvince", extractedInfo?.provinceChosenFull);
      setFieldValue("storePostCode", extractedInfo?.postCode);
    }
  }, [extractedInfo]);

  useEffect(() => {
    if (ownerExtractedInfo) {


      setFieldValue("ownerPostCode", ownerExtractedInfo?.postCode);
      setFieldValue("ownerCity", ownerExtractedInfo?.provinceChosenFull);
    }
  }, [ownerExtractedInfo]);

  useEffect(() => {
    validateForm();
    if (formIndex == 1) {
      pageProps.setIsOtherSteps(false)
    }
  }, [validateForm, formIndex]);



  const handleFieldChange = async (e: any) => {
    const { name } = e.target;
    handleChange(e);

    // Mark field as touched when it changes
    setTouched({ ...touched, [name]: true });

    // Validate the field immediately
    await validateField(name);
  };

  const handleFieldBlur = async (e: any) => {
    const { name } = e.target;
    handleBlur(e);

    // Mark field as touched when it blurs
    setTouched({ ...touched, [name]: true });

    // Validate the field immediately
    await validateField(name);

  };



  const handleSelectedAddress = async (address: any) => {
    setAddressSearched(address);



    const [place] = await geocodeByPlaceId(address?.value.place_id);
    let addressMeta = place?.address_components;




    geocodeByAddress(address?.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }: any) => {


        setAddressLatLng({ lat, lng });
      });

    let extractedInfoFromAddress = () => {
      let streetNumber: any = "",
        streetName: any = "",
        areaName: any = "",
        townName: any = "",
        postalCodeArea: any = "",
        fullAddress: any,
        topLevelArea: any,
        provinceChosenFull: any,
        provinceChosen: any;

      for (
        let componentIndex = 0;
        componentIndex < addressMeta.length;
        componentIndex++
      ) {
        if (
          addressMeta[componentIndex]?.types.length === 1 &&
          addressMeta[componentIndex]?.types[0] === "postal_code" &&
          !extractedData?.postCode
        ) {
          extractedData.postCode = addressMeta[componentIndex]?.long_name;
        }

        if (
          addressMeta[componentIndex]?.types.length === 1 &&
          addressMeta[componentIndex]?.types[0] === "street_number" &&
          streetNumber === ""
        ) {
          streetNumber = `${addressMeta[componentIndex]?.long_name}`;
        }

        if (
          addressMeta[componentIndex]?.types.length === 1 &&
          addressMeta[componentIndex]?.types[0] === "route" &&
          streetName === ""
        ) {
          streetName = `${streetNumber !== "" ? ", " : ""}${addressMeta[componentIndex]?.long_name
            }`;
        }

        if (
          addressMeta[componentIndex]?.types.length >= 1 &&
          (addressMeta[componentIndex]?.types.includes("neighborhood") ||
            addressMeta[componentIndex]?.types.includes("locality")) &&
          areaName.split(",").length - 1 < 1

          // && areaName === ""
        ) {
          areaName += `${streetNumber !== "" || streetName !== "" || areaName !== ""
            ? ", "
            : ""
            }${addressMeta[componentIndex]?.long_name}`;
        }

        if (
          addressMeta[componentIndex]?.types.length >= 2 &&
          addressMeta[componentIndex]?.types.includes(
            "administrative_area_level_5"
          ) &&
          areaName.split(",").length - 1 < 1
        ) {
          areaName += `${streetNumber !== "" || streetName !== "" || areaName !== ""
            ? ", "
            : ""
            }${addressMeta[componentIndex]?.long_name}`;
        }

        if (
          addressMeta[componentIndex]?.types.length >= 2 &&
          addressMeta[componentIndex]?.types.includes(
            "administrative_area_level_4"
          ) &&
          areaName.split(",").length - 1 < 1
        ) {
          areaName += `${streetNumber !== "" || streetName !== "" || areaName !== ""
            ? ", "
            : ""
            }${addressMeta[componentIndex]?.long_name}`;
        }

        if (
          addressMeta[componentIndex]?.types.length >= 2 &&
          addressMeta[componentIndex]?.types.includes(
            "administrative_area_level_3"
          )
          // && areaName === ""
        ) {
          townName += `${streetNumber !== "" || streetName !== "" || areaName !== ""
            ? ", "
            : ""
            }${addressMeta[componentIndex]?.long_name}`;
        }

        if (
          addressMeta[componentIndex]?.types.length >= 2 &&
          addressMeta[componentIndex]?.types.includes(
            "administrative_area_level_2"
          )
          // && areaName === ""
        ) {
          townName += `${streetNumber !== "" || streetName !== "" || areaName !== ""
            ? ", "
            : ""
            }${addressMeta[componentIndex]?.long_name}`;
        }



        if (
          addressMeta[componentIndex]?.types.length >= 2 &&
          (addressMeta[componentIndex]?.types.includes(
            "administrative_area_level_3"
          ) ||
            addressMeta[componentIndex]?.types.includes(
              "administrative_area_level_4"
            ) ||
            addressMeta[componentIndex]?.types.includes(
              "administrative_area_level_5"
            ) ||
            addressMeta[componentIndex]?.types.includes("locality") ||
            addressMeta[componentIndex]?.types.includes(
              "administrative_area_level_2"
            ))
          // && postalCodeArea.length === 0
          // && areaName === ""
        ) {
          postalCodeArea = `${addressMeta[componentIndex]?.long_name}`;
        }

        if (
          addressMeta[componentIndex]?.types.length >= 2 &&
          // addressMeta[componentIndex]?.types.includes("administrative_area_level_2") ||
          addressMeta[componentIndex]?.types.includes(
            "administrative_area_level_1"
          ) &&
          postalCodeArea.split(",").length - 1 < 1
        ) {
          postalCodeArea += `${postalCodeArea !== "" ? ", " : ""}${addressMeta[componentIndex]?.short_name
            }`;
          topLevelArea = addressMeta[componentIndex]?.long_name;
        }
        if (
          addressMeta[componentIndex]?.types.length >= 2 &&
          // addressMeta[componentIndex]?.types.includes("administrative_area_level_2") ||
          addressMeta[componentIndex]?.types.includes(
            "administrative_area_level_1"
          )
        ) {
          provinceChosen = addressMeta[componentIndex]?.short_name;
          provinceChosenFull = addressMeta[componentIndex]?.long_name;
        }

        // fullAddress = `${streetNumber} ${streetName} ${areaName} ${townName}`;
        fullAddress = `${streetNumber} ${streetName} ${areaName}`;
        extractedData.newAddress = fullAddress;
        extractedData.postalCodeArea = postalCodeArea;
        extractedData.topLevelArea = topLevelArea;
        extractedData.provinceChosen = provinceChosen;
        extractedData.provinceChosenFull = provinceChosenFull;

        if (
          extractedData?.postCode &&
          extractedData?.newAddress &&
          extractedData?.provinceChosen &&
          extractedData?.provinceChosenFull
        ) {
          break;
        }
      }



      return extractedData;
    };
    setExtractedInfo(extractedInfoFromAddress());
  };

  const handleSelectedOwnerAddress = async (address: any) => {
    setOwnerAddress(address);

    // getLatLng

    const [place] = await geocodeByPlaceId(address?.value.place_id);
    let addressMeta = place?.address_components;

    let extractedInfoFromAddress = () => {
      let streetNumber: any = "",
        streetName: any = "",
        areaName: any = "",
        townName: any = "",
        postalCodeArea: any = "",
        fullAddress: any,
        topLevelArea: any,
        provinceChosenFull: any,
        provinceChosen: any;

      for (
        let componentIndex = 0;
        componentIndex < addressMeta.length;
        componentIndex++
      ) {
        if (
          addressMeta[componentIndex]?.types.length === 1 &&
          addressMeta[componentIndex]?.types[0] === "postal_code" &&
          !extractedData?.postCode
        ) {
          extractedData.postCode = addressMeta[componentIndex]?.long_name;
        }

        if (
          addressMeta[componentIndex]?.types.length === 1 &&
          addressMeta[componentIndex]?.types[0] === "street_number" &&
          streetNumber === ""
        ) {
          streetNumber = `${addressMeta[componentIndex]?.long_name}`;
        }

        if (
          addressMeta[componentIndex]?.types.length === 1 &&
          addressMeta[componentIndex]?.types[0] === "route" &&
          streetName === ""
        ) {
          streetName = `${streetNumber !== "" ? ", " : ""}${addressMeta[componentIndex]?.long_name
            }`;
        }

        if (
          addressMeta[componentIndex]?.types.length >= 1 &&
          (addressMeta[componentIndex]?.types.includes("neighborhood") ||
            addressMeta[componentIndex]?.types.includes("locality")) &&
          areaName.split(",").length - 1 < 1

          // && areaName === ""
        ) {
          areaName += `${streetNumber !== "" || streetName !== "" || areaName !== ""
            ? ", "
            : ""
            }${addressMeta[componentIndex]?.long_name}`;
        }

        if (
          addressMeta[componentIndex]?.types.length >= 2 &&
          addressMeta[componentIndex]?.types.includes(
            "administrative_area_level_5"
          ) &&
          areaName.split(",").length - 1 < 1
        ) {
          areaName += `${streetNumber !== "" || streetName !== "" || areaName !== ""
            ? ", "
            : ""
            }${addressMeta[componentIndex]?.long_name}`;
        }

        if (
          addressMeta[componentIndex]?.types.length >= 2 &&
          addressMeta[componentIndex]?.types.includes(
            "administrative_area_level_4"
          ) &&
          areaName.split(",").length - 1 < 1
        ) {
          areaName += `${streetNumber !== "" || streetName !== "" || areaName !== ""
            ? ", "
            : ""
            }${addressMeta[componentIndex]?.long_name}`;
        }

        if (
          addressMeta[componentIndex]?.types.length >= 2 &&
          addressMeta[componentIndex]?.types.includes(
            "administrative_area_level_3"
          )
          // && areaName === ""
        ) {
          townName += `${streetNumber !== "" || streetName !== "" || areaName !== ""
            ? ", "
            : ""
            }${addressMeta[componentIndex]?.long_name}`;
        }

        if (
          addressMeta[componentIndex]?.types.length >= 2 &&
          addressMeta[componentIndex]?.types.includes(
            "administrative_area_level_2"
          )
          // && areaName === ""
        ) {
          townName += `${streetNumber !== "" || streetName !== "" || areaName !== ""
            ? ", "
            : ""
            }${addressMeta[componentIndex]?.long_name}`;
        }



        if (
          addressMeta[componentIndex]?.types.length >= 2 &&
          (addressMeta[componentIndex]?.types.includes(
            "administrative_area_level_3"
          ) ||
            addressMeta[componentIndex]?.types.includes(
              "administrative_area_level_4"
            ) ||
            addressMeta[componentIndex]?.types.includes(
              "administrative_area_level_5"
            ) ||
            addressMeta[componentIndex]?.types.includes("locality") ||
            addressMeta[componentIndex]?.types.includes(
              "administrative_area_level_2"
            ))
          // && postalCodeArea.length === 0
          // && areaName === ""
        ) {
          postalCodeArea = `${addressMeta[componentIndex]?.long_name}`;
        }

        if (
          addressMeta[componentIndex]?.types.length >= 2 &&
          // addressMeta[componentIndex]?.types.includes("administrative_area_level_2") ||
          addressMeta[componentIndex]?.types.includes(
            "administrative_area_level_1"
          ) &&
          postalCodeArea.split(",").length - 1 < 1
        ) {
          postalCodeArea += `${postalCodeArea !== "" ? ", " : ""}${addressMeta[componentIndex]?.short_name
            }`;
          topLevelArea = addressMeta[componentIndex]?.long_name;
        }
        if (
          addressMeta[componentIndex]?.types.length >= 2 &&
          // addressMeta[componentIndex]?.types.includes("administrative_area_level_2") ||
          addressMeta[componentIndex]?.types.includes(
            "administrative_area_level_1"
          )
        ) {
          provinceChosen = addressMeta[componentIndex]?.short_name;
          provinceChosenFull = addressMeta[componentIndex]?.long_name;
        }

        // fullAddress = `${streetNumber} ${streetName} ${areaName} ${townName}`;
        fullAddress = `${streetNumber} ${streetName} ${areaName}`;
        extractedData.newAddress = fullAddress;
        extractedData.postalCodeArea = postalCodeArea;
        extractedData.topLevelArea = topLevelArea;
        extractedData.provinceChosen = provinceChosen;
        extractedData.provinceChosenFull = provinceChosenFull;

        if (
          extractedData?.postCode &&
          extractedData?.newAddress &&
          extractedData?.provinceChosen &&
          extractedData?.provinceChosenFull
        ) {
          break;
        }
      }



      return extractedData;
    };
    setOwnerExtractedInfo(extractedInfoFromAddress());
  };







  const handleFormNextStep = async (isBack?: boolean) => {

    if (isBack) {
      setFormIndex((prevStep) => prevStep - 1);
      return;
    }
    if (formIndex == 1) {
      setTouched({
        storeEmail: true,
        password: true,
      });
    }

    if (formIndex == 2) {
      setTouched({
        storeName: true,
        storeUrl: true,
        storeCats: true,
        storeAddress: true,
        storeProvince: true,
        storePostCode: true,
        phoneNumber: true,
      });
    }

    if (formIndex == 3) {
      setTouched({
        firstName: true,
        lastName: true,
        ownerPhone: true,
        ownerPostCode: true,
        ownerCity: true,
        ownerAddress: true,
        howHear: true,
        acceptTerms: true,
      });
    }

    const formErrors = await validateForm();


    // Check if there are any errors
    if (Object.keys(formErrors).length === 0) {
      if (formIndex == 1) {
        pageProps.setIsOtherSteps(true)
        pageProps.getStoreUrlAction("CLEAR")
      }
      setFormIndex((prevStep) => prevStep + 1);

    } else {

    }





  };

  const checkStepValidity = (values: any, errors: any, touched: any, currentStep: any) => {
    let isValid = true;

    switch (currentStep) {
      case 2:
        isValid =
          !Object.keys(errors).length
          && Object.keys(touched).length > 0 &&
          values.storeName && values.storeUrl && values.storeCats.length > 0 &&
          values.storeAddress && values.storeProvince && values.storePostCode && values.phoneNumber
        break;
      case 3:
        isValid =
          !Object.keys(errors).length && Object.keys(touched).length > 0 &&
          values.firstName && values.lastName && values.ownerPhone &&
          values.ownerPostCode && values.ownerCity && values.ownerAddress && values.howHear
        break;
      default:
        break;
    }

    return isValid;
  };





  // Check if the current step is valid
  const isCurrentStepValid = checkStepValidity(values, errors, touched, formIndex);

  useEffect(() => {
    setStepValidities((prev) => {
      const updated = [...prev];
      updated[formIndex - 1] = isCurrentStepValid;
      return updated;
    });

    if (!Object.keys(errors).length
      && Object.keys(touched).length > 0 &&
      values.storeName && values.storeUrl && values.storeCats.length > 0 &&
      values.storeAddress && values.storeProvince && values.storePostCode && values.phoneNumber) {
      setIsStepTwoValid(true)
    }

    if (!Object.keys(errors).length
      && Object.keys(touched).length > 0 &&
      values.firstName && values.lastName && values.ownerPhone &&
      values.ownerPostCode && values.ownerCity && values.ownerAddress && values.howHear && values.acceptTerms) {
      setIsStepThreeValid(true)
    }
  }, [isCurrentStepValid, formIndex]);

  // // Update the step validity
  // if (isCurrentStepValid !== stepValidities[formIndex - 1]) {
  //   setStepValidities((prev) => {
  //     const updated = [...prev];
  //     updated[formIndex - 1] = isCurrentStepValid;
  //     return updated;
  //   });
  // }




  return (
    <div className="create-form" id="signup-form">
      {pageProps?.creatUserRequest?.request_status &&
        pageProps.creatUserRequest.request_status !==
        "CREATE_ACCOUNT_SUCCESS" && (
          <div className="signup-wrap">
            <div className="flex">
              <h4 className="formTitle">
                {formIndex == 1 ? "Create a seller account" : "Welcome to MamiMarkets!"}
              </h4>
              {/* {
                formIndex === 2 && <p onClick={() => setFormIndex(1)} className="times">
                  &larr;
                </p>
              } */}
            </div>
            <p className="text more-desc" style={{ marginTop: '.2rem' }}>
              {formIndex == 1 ? "Manage your products, track sales, and stay connected with your customers" : "Provide some information for a faster and easier setup"}
            </p>
            {formIndex === 1 &&
              <div className="initial-fields">
                <div className="inputbox2">
                  <label htmlFor="" className="label">Email</label>
                  <input
                    type="email"
                    name="storeEmail"
                    onChange={handleFieldChange}
                    onBlur={handleFieldBlur}
                    placeholder="Email address"
                    value={values.storeEmail}
                    className={`input-wrap email-txt inapp-input ${errors.storeEmail && touched.storeEmail ?
                      "is-invalid-input "
                      : !errors.storeEmail && values.storeEmail !== "" ? "valid-input" : ""}`}
                  />
                  {!!errors.storeEmail && touched.storeEmail && (
                    <p className="error">{errors.storeEmail}</p>
                  )}
                </div>
                <div className="inputbox2">
                  <label htmlFor="" className="label">Password</label>
                  <input
                    name="password"
                    onChange={handleFieldChange}
                    onBlur={handleFieldBlur}
                    value={values.password}
                    type={!showPassword ? "password" : "text"}
                    placeholder="Enter your password"
                    className={`input-wrap inapp-input ${errors.password && touched.password ?
                      "is-invalid-input "
                      : !errors.password && values.password !== "" ? "valid-input" : ""}`}
                  />
                  <div onClick={() => setShowPassword((prev) => !prev)}>
                    {!showPassword ? (
                      <AiFillEye className="eye" />
                    ) : (
                      <AiFillEyeInvisible className="eye" />
                    )}
                  </div>
                  {!!errors.password && touched.password && (
                    <p className="error">{errors.password}</p>
                  )}
                </div>

                <button onClick={() => handleFormNextStep()} type="button" className="continue full">Create Seller account</button>
                {/* <button onClick={() => {handleFormNextStep(validateField, setFieldTouched)}} type="button" className="continue full">Create Seller account</button> */}
                <div className="separator">
                  <hr className="separator-line" />
                  <span className="separator-text">or</span>
                  <hr className="separator-line" />
                </div>
                <div className="have-account">Do you already have an account?
                  <Link to="/log-in" className="terms-link"> Log in</Link>
                </div>
                <p className="term-text">
                  By proceeding, you agree to the
                  <a href="https://mamimarkets.com/terms" className="terms-link"> Terms of Service</a> and
                  <a href="https://mamimarkets.com/privacy-policy" className="privacy"> Privacy Policy</a>.
                </p>
              </div>
            }
            {formIndex > 1 &&


              <div className="steps-continue">

                <div className="flex">

                  <div className="line">
                    {/* <div className={`line_bg ${isCurrentStepValid && formIndex >= 2 ? "line_bg_full" : ""}`}></div> */}
                    <div className={`line_bg ${isStepTwoValid ? "line_bg_full" : ""}`}></div>
                  </div>
                  <div className="line">
                    {/* <div className={`line_bg ${isCurrentStepValid && formIndex == 3 ? "line_bg_full" : ""}`}></div> */}
                    <div className={`line_bg ${isStepThreeValid && formIndex == 3 ? "line_bg_full" : ""}`}></div>
                  </div>

                  {/* {[...Array(2)].map((_, elem) => {
                    const stepIndex = elem + 1;
                    console.log("stepIndex", stepIndex)
                    console.log("formIndex", formIndex)
                    console.log("isCurrentStepValid", isCurrentStepValid)
                    return (
                      <div key={stepIndex} className={`line `}>
                        <div
                          className={`line_bg line_${elem} ${stepIndex === formIndex ? (isCurrentStepValid ? 'valid' : 'invalid') : ''
                            }`}
                          // className={`line_bg ${stepIndex <= formIndex ? 'completed' : ''} ${
                          //   stepIndex === formIndex ? 'current' : ''
                          // }`}
                          style={{
                            height: "100%"
                            // height: "100%", width: `${stepIndex <= formIndex ? '100%' : ''} ${stepIndex === formIndex ? '0px' : ''
                            //   }`
                          }}
                        // style={{ height: "100%", width: `${formIndex >= elem && Object.keys(errors).length == 0 ? "100%" : "0px"}` }}
                        />
                      </div>
                    )
                  }
                  )} */}
                </div>

                {formIndex === 2 && (
                  <div className="store-details">

                    <h5 className="store-title">Store information</h5>
                    <div
                      className="flex"
                      style={{ marginTop: "1rem" }}
                    >
                      <div className="store-logo" style={{ position: "relative" }}>
                        <div className="logo-icon">
                          <img src={storeLogo?.image} alt="" />
                          {/* <label htmlFor="vendor-logo">{isStepTwoValid ? "change logo" : "upload logo"} </label> */}
                          <input
                            accept="image/*"
                            onChange={(e: any) => handleLogoUpload(e)}
                            type="file"
                            name=""
                            id="vendor-logo"
                            style={{
                              position: "absolute",
                              left: "0",
                              top: "2%",
                              opacity: "0",
                            }}
                          />
                        </div>
                      </div>
                      <div className="inputbox storename-wrap" id="form">
                        <input
                          name="storeName"
                          onChange={handleFieldChange}
                          onBlur={(event) => {
                            handleFieldBlur(event);


                            pageProps.getStoreUrlAction(values.storeName);
                          }}
                          type="text"
                          placeholder="Store name"
                          className={`input-wrap inapp-input ${errors.storeName && touched.storeName ?
                            "is-invalid-input "
                            : !errors.storeName && values.storeName !== "" ? "valid-input" : ""}`}
                        />
                        {pageProps?.getUrlRequest?.request_status == "GET_URL_OPTIONS_PENDING" &&
                          <div className="spinner"></div>
                        }
                        {!!errors.storeName && touched.storeName && (
                          <p className="error">{errors.storeName}</p>
                        )}

                        <p className="link">{values.storeUrl || "https://mamimarkets.com/"}</p>
                      </div>
                    </div>

                    <div>
                      <div className="inputbox2 url-field">
                        <input
                          name="storeUrl"
                          value={
                            pageProps?.getUrlRequest?.request_status ===
                              "GET_URL_OPTIONS_PENDING"
                              ? "Please wait..."
                              : values.storeUrl
                          }
                          onChange={() => { }}
                          onBlur={() => { }}
                          type="text"
                          placeholder="Store url"
                          className={`input-wrap inapp-input ${errors.storeUrl && touched.storeUrl ?
                            "is-invalid-input "
                            : !errors.storeUrl && values.storeUrl !== "" ? "valid-input" : ""}`}
                        />
                        {pageProps?.getUrlRequest?.request_status == "GET_URL_OPTIONS_PENDING" &&
                          <div className="spinner"></div>
                        }
                        {!!errors.storeUrl && touched.storeUrl && (
                          <p className="error">{errors.storeUrl}</p>
                        )}
                      </div>
                      {app_state?.getUrlReducer?.request_status ===
                        "GET_URL_OPTIONS_SUCCESS" && (
                          <div className="url_options">
                            <p className="url_header">Choose your preferred store url</p>
                            {app_state?.getUrlReducer?.request_data?.response.map(
                              (eachItem: any, index: any) => {
                                return (
                                  <div className={`each-options ${values.storeUrl === eachItem ? "chosen-url" : ""} `} key={index}>
                                    {/* <span className={`custom_radio`}> */}
                                    
                                      <span
                                        className={`custom_radio ${values.storeUrl === eachItem ? "selected_radio" : ""
                                          } `}
                                      >
                                        <input
                                          onChange={(e) => {
                                            setFieldValue("storeUrl", e.target.value);
                                          }}
                                          name="storeUrl"
                                          type="radio"
                                          id={eachItem}
                                          value={eachItem}
                                        />
                                      </span>
                                    
                                    <label htmlFor={eachItem}>{eachItem}</label>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        )}

                      <div className="inputbox2">
                        <MultiSelect
                          hasSelectAll={false}
                          options={categories}
                          value={values.storeCats}
                          onChange={(selectedCats: any) => {
                            setFieldValue("storeCats", selectedCats);
                            setFieldTouched("storeCats", true);
                          }}
                          labelledBy="Select"
                          className={`biz-cat ${errors.storeCats || !Boolean(values.storeCats.length) ? "invalid-input-wrap" : ""
                            }`}
                          overrideStrings={{
                            search: "Search Categories",
                            selectSomeItems: "Choose Categories",
                            allItemsAreSelected: "All Categories are chosen.",
                            noOptions: "No categories match",
                          }}
                        />

                        {!Boolean(values.storeCats.length) && touched.storeCats && (
                          <p className="error">Select a minimum of one category</p>
                        )}
                      </div>
                      <div className="inputbox2">
                        <GooglePlacesAutocomplete
                          selectProps={{
                            value: addressSearched,
                            onChange: (address) => {
                              handleSelectedAddress(address);
                              setFieldValue("storeAddress", "set");

                              setFieldError("storeAddress", "");
                            },
                            placeholder: "Store address",
                            // className: "auto",
                            className: `address-search auto ${errors.storeAddress && touched.storeAddress ?
                              "is-invalid-input "
                              : !errors.storeAddress && values.storeAddress !== "" ? "valid-input" : ""}`,
                            openMenuOnClick: false,
                            noOptionsMessage: () => "Type a valid address",
                            styles: {
                              input: () => ({
                                height: "44px",
                                fontSize: "13px",
                                border: "none",
                              }),
                            },
                          }}
                          autocompletionRequest={{
                            componentRestrictions: {
                              country: ["ca"],
                            },
                          }}
                          apiKey={appConstants.GOOGLE_MAP_KEY}
                        />
                        {!!errors.storeAddress && touched.storeAddress && (
                          <p className="error">{errors.storeAddress}</p>
                        )}
                      </div>
                      <div className="inputbox2">
                        <input
                          value={values.storePostCode}
                          disabled
                          type="text"
                          placeholder="Postal code"
                          className={`input-wrap inapp-input ${errors.storePostCode && touched.storePostCode ?
                            "is-invalid-input "
                            : !errors.storePostCode && values.storePostCode !== "" ? "valid-input" : ""}`}
                        />
                        {!!errors.storePostCode && touched.storePostCode && (
                          <p className="error">{errors.storePostCode}</p>
                        )}
                      </div>
                      <div className="inputbox2">
                        <input
                          value={values.storeProvince}
                          disabled
                          type="text"
                          placeholder="Store province"
                          className={`input-wrap inapp-input ${errors.storeProvince && touched.storeProvince ?
                            "is-invalid-input "
                            : !errors.storeProvince && values.storeProvince !== "" ? "valid-input" : ""}`}
                        />
                        {!!errors.storeProvince && touched.storeProvince && (
                          <p className="error">{errors.storeProvince}</p>
                        )}
                      </div>
                      <div className="inputbox2">
                        {/* <input type="text" placeholder="Phone number" /> */}
                        <PhoneInput
                          country={"ca"}
                          enableTerritories={false}
                          enableSearch={true}
                          disableSearchIcon={true}
                          inputClass={`input-wrap ${errors.phoneNumber && touched.phoneNumber
                            ? "is-invalid-input "
                            : values.phoneNumber !== ""
                              ? "valid-input"
                              : ""
                            }`}
                          value={values.phoneNumber}
                          onChange={(phone: string): void => {
                            setFieldTouched("phoneNumber", true);
                            setFieldValue("phoneNumber", phone);
                          }}
                        />
                        {!!errors.phoneNumber && touched.phoneNumber && (
                          <p className="error">{errors.phoneNumber}</p>
                        )}
                      </div>

                    </div>
                    <div className="flex2 continue-wrap " style={{ marginTop: "2rem" }}>
                      {/* <p className="text">
                        already have an account?
                        <span style={{ color: "#00A85B", fontWeight: "500" }}>
                          {" "}

                          <Link className="login-link" to="/login"> Log in</Link>
                        </span>
                      </p> */}

                      <button onClick={() => handleFormNextStep(true)} className="back">
                        &lt; Back
                      </button>
                      <button onClick={() => handleFormNextStep()} className={`continue-now ${Object.keys(errors).length == 0 ? "valid" : ""}`}>
                        Next &gt;
                      </button>
                    </div>
                  </div>
                )}
                {formIndex === 3 && (
                  <div className="personal-data">
                    <h5 className="store-title">Personal information</h5>
                    <div>
                      <div className="flex-box">
                        <div className="input-container" style={{ marginBottom: "1rem" }}>
                          <select className="select2">
                            <option value="">Title</option>
                            <option value="Mrs">Mrs</option>
                            <option value="Ms.">Ms.</option>
                            <option value="Mr">Mr</option>
                          </select>
                          <div className="inputbox3">
                            <input
                              name="firstName"
                              onChange={handleFieldChange}
                              onBlur={handleFieldBlur}
                              type="text"
                              placeholder="First name"
                              className={`input-wrap inapp-input ${errors.firstName && touched.firstName ?
                                "is-invalid-input "
                                : !errors.firstName && values.firstName !== "" ? "valid-input" : ""}`}
                            />
                            {!!errors.firstName && touched.firstName && (
                              <p className="error">{errors.firstName}</p>
                            )}
                          </div>
                        </div>
                        <div className="inputbox3" style={{ marginBottom: "1rem" }}>
                          <input
                            type="text"
                            name="lastName"
                            onChange={handleFieldChange}
                            onBlur={handleFieldBlur}
                            placeholder="Last name"
                            className={`input-wrap inapp-input ${errors.firstName && touched.firstName ?
                              "is-invalid-input "
                              : !errors.firstName && values.firstName !== "" ? "valid-input" : ""}`}
                          />
                          {!!errors.lastName && touched.lastName && (
                            <p className="error">{errors.lastName}</p>
                          )}
                        </div>
                      </div>
                      <div className="inputbox2">
                        <GooglePlacesAutocomplete
                          selectProps={{
                            value: ownerAddressSearched,
                            // onChange: handleSelectedOwnerAddress,
                            onChange: (address) => {
                              handleSelectedOwnerAddress(address);
                              setFieldValue("ownerAddress", "set");
                            },
                            placeholder: "Store owner address",
                            openMenuOnClick: false,
                            classNamePrefix: "store-address-select",
                            className: `address-search auto ${errors.ownerAddress && touched.ownerAddress ?
                              "is-invalid-input "
                              : !errors.ownerAddress && values.ownerAddress !== "" ? "valid-input" : ""}`,
                            noOptionsMessage: () => "Type a valid address",
                            styles: {
                              input: () => ({
                                height: "44px",
                                fontSize: "13px",
                                border: "none",
                              }),
                            },
                          }}
                          autocompletionRequest={{
                            componentRestrictions: {
                              country: ["ca"],
                            },
                          }}
                          apiKey={appConstants.GOOGLE_MAP_KEY}
                        />
                      </div>

                      <div className="inputbox2">
                        <input
                          type="text"
                          disabled
                          value={values.ownerPostCode || ""}
                          placeholder="Store owner post code"
                          className={`input-wrap inapp-input ${errors.ownerPostCode && touched.ownerPostCode ?
                            "is-invalid-input "
                            : !errors.ownerPostCode && values.ownerPostCode !== "" ? "valid-input" : ""}`}
                        />
                      </div>
                      <div className="inputbox2">
                        <input
                          type="text"
                          disabled
                          value={values.ownerCity}
                          placeholder="Store owner province"
                          className={`input-wrap inapp-input ${errors.ownerCity && touched.ownerCity ?
                            "is-invalid-input "
                            : !errors.ownerCity && values.ownerCity !== "" ? "valid-input" : ""}`}
                        />
                      </div>
                      <div className="inputbox2">
                        <PhoneInput
                          country={"ca"}
                          enableTerritories={false}
                          enableSearch={true}
                          disableSearchIcon={true}
                          inputClass={`input-wrap ${errors.ownerPhone && touched.ownerPhone
                            ? "is-invalid-input "
                            : values.ownerPhone !== ""
                              ? "valid-input"
                              : ""
                            }`}
                          placeholder="Store owner phone number"
                          value={values.ownerPhone}
                          onChange={(phone: string): void => {
                            setFieldTouched("ownerPhone", true);
                            setFieldValue("ownerPhone", phone);
                          }}
                        />
                      </div>

                      <select
                        onChange={handleFieldChange}
                        onBlur={handleFieldBlur}
                        name="howHear"
                        className={`select input-wrap inapp-input ${errors.howHear && touched.howHear ?
                          "is-invalid-input "
                          : !errors.howHear && values.storeName !== "" ? "valid-input" : ""}`}
                        style={{ marginTop: "1rem" }}
                      >
                        <option value="">How did you hear about us</option>
                        <option value="Google Search">Google Search</option>
                        <option value="Social Media">Social Media</option>
                        <option value="Friend">Friend</option>
                        <option value="YouTube">YouTube</option>
                        <option value="Others">Others</option>
                      </select>
                      {!!errors.howHear && touched.howHear && (
                        <p className="error">{errors.howHear}</p>
                      )}
                      <div className="flexer">
                        <input
                          type="checkbox"
                          name="acceptTerms"
                          onChange={handleFieldChange}
                          onBlur={handleFieldBlur}
                          className="check" />
                        <span className="terms">
                          I accept MamiMarket&apos;s{" "}
                          <p className="terms2"> Terms of Service</p> and{" "}
                          <p className="terms2"> Privacy Policy</p>
                        </span>
                      </div>
                    </div>
                    <div>
                      {pageProps?.creatUserRequest?.request_status &&
                        pageProps.creatUserRequest.request_status ===
                        "CREATE_ACCOUNT_FAILURE" && (
                          <AlertMsg
                            type="error"
                            message={pageProps.creatUserRequest?.request_data.error}
                          />
                        )}
                    </div>

                    {/* <div className="flex2" style={{ marginTop: "2rem" }}>
                      <p className="text">
                        already have an account? <br />
                        <span style={{ color: "#00A85B", fontWeight: "500" }}>
                          {" "}
                          <Link className="login-link" to="/login"> Log in</Link>
                        </span>
                      </p>

                      <div className="ctas_wrapper">

                        <button onClick={() => setFormIndex(1)} className="continue back_cta">&larr; </button>
                        <button onClick={() => handleSubmit()} className="continue">
                          {pageProps.creatUserRequest.is_request_processing
                            ? "Please wait..."
                            : "Sign up"}
                        </button>
                      </div>
                    </div> */}

                    <div className="flex2 continue-wrap " style={{ marginTop: "2rem" }}>
                      {/* <p className="text">
                        already have an account?
                        <span style={{ color: "#00A85B", fontWeight: "500" }}>
                          {" "}

                          <Link className="login-link" to="/login"> Log in</Link>
                        </span>
                      </p> */}

                      <button onClick={() => handleFormNextStep(true)} className="back">
                        &lt; Back
                      </button>
                      <button onClick={handleSubmit} className={`continue-now ${Object.keys(errors).length == 0 ? "valid" : ""}`}>
                        Get started  &rarr;
                      </button>
                    </div>
                  </div>
                )}
              </div>
            }
          </div>
        )}
      {pageProps?.creatUserRequest?.request_status &&
        pageProps.creatUserRequest.request_status ===
        "CREATE_ACCOUNT_SUCCESS" && (
          <div className="success">
            <div className="success-animation">
              <svg
                className="success_icon_tick"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                <circle
                  className="checkmark__circle"
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />
                <path
                  className="checkmark__check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="success-message-txt">
                Your account has been created. Please click the link in the
                email sent to you
              </div>
              {/* <div className="resend">Didn't receive link? Click here</div> */}
            </div>
          </div>
        )}
    </div>
  );
};

const mapDispatchToProps = {
  createAccountAction: authActions.CreateAccount,
  getStoreUrlAction: authActions.GetStoreUrl,
  getStoreCategoriesAction: authActions.GetStoreCategories,
  logoutAction: authActions.Logout,
};

const mapStateToProps = (state: any) => ({
  getUrlRequest: state.onboardingOutboundReducers.getUrlReducer,
  getStoreCatsRequest: state.onboardingOutboundReducers.getStoreCatsReducer,
  creatUserRequest: state.onboardingOutboundReducers.creatUserReducer,
});
export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
