import React, { useState, useEffect } from 'react';
import { ChildrenCompProps } from "../../types";
import "../../scss/onboarding.scss"
import Logo from "../../assets/logo.png"
import { useWindowSize } from "../../hooks/useWindowSize";

export const PostBoardingWrap: React.FC<ChildrenCompProps> = (props) => {
    const { childComponent, pageHeadingTitle, className, innerWrapper, isOtherSteps, isV2 } = props;
    // localStorage.removeItem("mamiVendorXAuth");
    // localStorage.removeItem("state");
    const screenResolution: any = useWindowSize();

    
    return (
        <div className= {`postboarding-wrap ${isOtherSteps?"centered-bg-greeen":""}`}>
            <div className={`postboarding-container ${isV2?"v2-postboarding":""}`}>
                <div className="page-meta">
                    <div className="logo-wrap">
                        <img src={Logo} alt="Logo" />
                    </div>
                    {pageHeadingTitle &&
                        <div className="page-title">{pageHeadingTitle}</div>
                    }
                </div>
                <div className={`postboard-form ${innerWrapper||""}`}>
                    {childComponent}
                </div>
            </div>
            {!isOtherSteps && screenResolution?.width > 1024 &&
            <div className={className ||'login-bg'}>

            </div>
            }
        </div>
    )
}