import { PropsWithChildren } from "react";
import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import { connect } from 'react-redux';
import { PrivateRoutes } from "../types"
import LogIn from "../modules/onboarding/login"
import ForgotPassword from "../modules/onboarding/login/forgot-password"
import ResetPassword from "../modules/onboarding/reset-password"
import SignUp from "../modules/onboarding/signup"
import OnboardVerification from "../modules/onboarding/signup/email-verify"

import Dashboard from "../modules/inapp/dashboard"
import NoStoresFound from "../modules/inapp/dashboard/no-stores"
import CustomersList from "../modules/inapp/customers"
import ProductList from "../modules/inapp/products"
import AddNewProduct from "../modules/inapp/products/addnew"
import AddMultipleProducts from "../modules/inapp/products/add-multiple"
import NewProductSuccess from "../modules/inapp/products/success"
import EditProduct from "../modules/inapp/products/edit"
import OrderList from "../modules/inapp/orders"
import OrderDetails from "../modules/inapp/orders/view-order"
import ReturnedOrderList from "../modules/inapp/orders/returned"

import PersonalProfile from "../modules/inapp/profile"
import StaffProfiles from "../modules/inapp/profile/staff"
import DeleteProfile from "../modules/inapp/profile/delete-account"
import Withdrawal from "../modules/inapp/profile/withdrawal"
import StoreProfile from "../modules/inapp/profile/store"
import LandingPage from "../modules/onboarding/landing-page";
import NewLandingPage from "../modules/onboarding/landing-page/v2";
import GetStartedPage from "../modules/onboarding/landing-page/get-started";
import NewLogin from "../modules/onboarding/landing-page/login";
import NewForgotPw from "../modules/onboarding/landing-page/forgot-password";
import HelpTab from "../modules/inapp/help-center";
import HelpCenter from "../modules/inapp/help-center";


const ProtectedRoute = ({
    isAllowed,
    redirectPath = '/',
    children,
    isNoStoresPath,
}: PrivateRoutes) => {



    if (!isAllowed) {
        return <Navigate to={redirectPath} replace />;
    }


   
    let loggedAccountInfo: any = localStorage.getItem("mamiVendorXAuth") || "{}"
    loggedAccountInfo = JSON.parse(loggedAccountInfo);

    if (loggedAccountInfo?.myStores && !isNoStoresPath) {
        return children ? children : <Outlet />;
    }
    return <NoStoresFound />;



    // return children ? children : <Outlet />;
};

type OwnProps = ReturnType<typeof mapStateToProps>;

const AppRoutes = (pageProps: PropsWithChildren<OwnProps>) => {


    return (
        <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/v2" element={<NewLandingPage />} />
            <Route path="/get-started" element={<GetStartedPage />} />
            <Route path="/log-in" element={<NewLogin />} />
            <Route path="/forgot-password" element={<NewForgotPw />} />
            <Route path="/login" element={<LogIn />} />
            <Route path="/account/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/account/create" element={<SignUp />} />
            <Route path="/account/verification" element={<OnboardVerification />} />

            <Route path="/app" element={
                <ProtectedRoute

                    isAllowed={
                        pageProps?.loginRequest?.loggedIn
                    }
                >
                    <Dashboard />
                </ProtectedRoute>
            } />
             <Route path="/app/help-center" element={
                <ProtectedRoute
                    isAllowed={
                        pageProps?.loginRequest?.loggedIn
                    }
                >
                    <HelpCenter />
                </ProtectedRoute>
            } />
            <Route path="/app/no-stores" element={
                <ProtectedRoute
                    isNoStoresPath={true}
                    isAllowed={
                        pageProps?.loginRequest?.loggedIn
                    }
                >
                    <NoStoresFound />
                </ProtectedRoute>
            } />
            <Route path="/app/my-customers" element={
                <ProtectedRoute
                    isAllowed={
                        pageProps?.loginRequest?.loggedIn
                    }
                >
                    <CustomersList />
                </ProtectedRoute>
            } />

            <Route path="/app/my-products" element={
                <ProtectedRoute
                    isAllowed={
                        pageProps?.loginRequest?.loggedIn
                    }
                >
                    <ProductList />
                </ProtectedRoute>
            } />

            <Route path="/app/my-products/new" element={
                <ProtectedRoute
                    isAllowed={
                        pageProps?.loginRequest?.loggedIn
                    }
                >
                    <AddNewProduct />
                </ProtectedRoute>
            } />
             <Route path="/app/my-products/new/multiple" element={
                <ProtectedRoute
                    isAllowed={
                        pageProps?.loginRequest?.loggedIn
                    }
                >
                    <AddMultipleProducts />
                </ProtectedRoute>
            } />



            <Route path="/app/my-products/success" element={
                <ProtectedRoute
                    isAllowed={
                        pageProps?.loginRequest?.loggedIn
                    }
                >
                    <NewProductSuccess />
                </ProtectedRoute>
            } />

            <Route path="/app/my-products/:productId/edit" element={
                <ProtectedRoute
                    isAllowed={
                        pageProps?.loginRequest?.loggedIn
                    }
                >
                    <EditProduct />
                </ProtectedRoute>
            } />

            <Route path="/app/my-orders" element={
                <ProtectedRoute
                    isAllowed={
                        pageProps?.loginRequest?.loggedIn
                    }
                >
                    <OrderList />
                </ProtectedRoute>
            } />

            <Route path="/app/my-orders/:orderId" element={
                <ProtectedRoute
                    isAllowed={
                        pageProps?.loginRequest?.loggedIn
                    }
                >
                    <OrderDetails />
                </ProtectedRoute>
            } />

            <Route path="/app/my-orders/cancelled" element={
                <ProtectedRoute
                    isAllowed={
                        pageProps?.loginRequest?.loggedIn
                    }
                >
                    <ReturnedOrderList />
                </ProtectedRoute>
            } />

            <Route path="/app/profile/personal" element={
                <ProtectedRoute
                    isAllowed={
                        pageProps?.loginRequest?.loggedIn
                    }
                >
                    <PersonalProfile />
                </ProtectedRoute>
            } />

            <Route path="/app/profile" element={
                <ProtectedRoute
                    isAllowed={
                        pageProps?.loginRequest?.loggedIn
                    }
                >
                    <StoreProfile />
                </ProtectedRoute>
            } />


            <Route path="/app/profile/staff" element={
                <ProtectedRoute
                    isAllowed={
                        pageProps?.loginRequest?.loggedIn
                    }
                >
                    <StaffProfiles />
                </ProtectedRoute>
            } />

            <Route path="/app/profile/delete" element={
                <ProtectedRoute
                    isAllowed={
                        pageProps?.loginRequest?.loggedIn
                    }
                >
                    <DeleteProfile />
                </ProtectedRoute>
            } />

            <Route path="/app/profile/withdrawal" element={
                <ProtectedRoute
                    isAllowed={
                        pageProps?.loginRequest?.loggedIn
                    }
                >
                    <Withdrawal />
                </ProtectedRoute>
            } />
            <Route path="/app/profile/store" element={
                <ProtectedRoute
                    isAllowed={
                        pageProps?.loginRequest?.loggedIn
                    }
                >
                    <StoreProfile />
                </ProtectedRoute>
            } />
            

        </Routes>
    )
}

const mapStateToProps = (state: any) => ({
    loginRequest: state.onboardingOutboundReducers.loginReducer,
})

export default connect(mapStateToProps)(AppRoutes);