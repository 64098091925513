import { useState, useEffect } from "react";
import '../../../scss/onboarding.scss';

import { connect } from 'react-redux';
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from "react-router-dom";
import { OnBoardingFormValues, ILoginProps } from "../../../types"
import GSign from "../../../assets/g-sign.svg"
import PwSeen from "../../../assets/icons/pw-visible.png"
import PwHide from "../../../assets/icons/pw-hidden.png"
import { PostBoardingWrap } from "../../../shared/templates/onboarding";
import { authActions } from "../../../redux/actions/onboarding"
import { AlertMsg } from "../../../shared/components/alert-msg"
import PersonalInfo from "./form/personal-info-v2";
import { useWindowSize } from "../../../hooks/useWindowSize";
const handleLogin = async ({ pageProps, payload }: any) => {

    await pageProps.LoginAction(payload);
}

const handleresendActivation = async ({ pageProps, payload }: any) => {

    await pageProps.resendActivationEmailAction(payload);
}

const PostBoardingContent = ({ pageProps, setIsOtherSteps }: any) => {
    const [isPwShown, setShowPw] = useState<boolean>(false)
    const [emailTxt, setEmailTxt] = useState<string>("")


    const screenResolution: any = useWindowSize();
    useEffect(() => {
        // pageProps.logoutAction();
        handleLogin({
            pageProps,
            payload: "CLEAR"
        })

        handleresendActivation({
            pageProps,
            payload: "CLEAR"
        })


    }, [])
    let checkValidationSchema = Yup.object().shape({
        username: Yup.string().trim()
            .email('Valid email is required')
            .required('Required'),
        password: Yup.string()
            .required('Required'),

    });
    let initialValues: any = { username: "", password: "" }
    return (
        <div className="postboarding-content postboarding-content-2">
            <div className="containers3">

                <PersonalInfo setIsOtherSteps={setIsOtherSteps} />
            </div>
            {/* <div className="optional">
                <span className="span">Don&apos;t have an account yet?<a href='/' style={{marginTop: '-.1rem'}}> Sign up</a></span>
            </div> */}

        </div>
    )
}


const GetStartedPage = (pageProps: any) => {
    const [isOtherSteps, setIsOtherSteps] = useState<boolean>(false)
    return (
        <PostBoardingWrap isV2={true} isOtherSteps={isOtherSteps} innerWrapper="inner-wrapper" className="v2-bg" pageHeadingTitle="Get Started" childComponent={<PostBoardingContent setIsOtherSteps={setIsOtherSteps} pageProps={pageProps} />} />
    )
}


const mapDispatchToProps = {
    LoginAction: authActions.Login,
    logoutAction: authActions.Logout,
    resendActivationEmailAction: authActions.ResendActivationEmail,
};

const mapStateToProps = (state: any) => ({
    loginRequest: state.onboardingOutboundReducers.loginReducer,
    resendActivationEmailRequest: state.onboardingOutboundReducers.resendActivationEmailReducer,
})
export default connect(mapStateToProps, mapDispatchToProps)(GetStartedPage);