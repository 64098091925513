import { Link } from "react-router-dom";
import { useWindowSize } from "../../../hooks/useWindowSize";
import bg1 from '../../../assets/bg1.webp'
import bg2 from '../../../assets/bg2.svg'
import bg3 from '../../../assets/bg3.svg'
import bg4 from '../../../assets/bg4.svg'

import '../../../scss/cards-v2.scss'
import person1 from '../../../assets/person1.webp'
import person2 from '../../../assets/person2.webp'
import person3 from '../../../assets/person3.webp'
import joinarrow from '../../../assets/landing/join-arrow.png'
import { useState } from 'react'
import { BiSolidQuoteAltLeft } from 'react-icons/bi'
import { questions } from '../../inapp/help-center/questions'
import { LiaAngleDownSolid } from 'react-icons/lia'
import Marquee from 'react-fast-marquee'


const comment = [
    {
        name: 'Tobi Lionel',
        data: {
            title: 'I experienced a seamless onboarding process that allowed me to set up my online store within minutes.',
            commenttop: 'As a vendor on MamiMarkets.com, I cannot express enough how grateful I am for the opportunities and support this platform has provided me.',
            commentdown: "But it doesn't stop there. MamiMarkets.com has truly opened doors for me to reach a vast customer base that I couldn't have accessed on my own. The platform's robust marketing features, such as product recommendations and targeted advertising, have significantly increased my brand visibility and attracted a steady stream of interested buyers. The enhanced market reach has allowed my small business to grow exponentially, and I've witnessed a remarkable increase in sales since joining the platform."
        }
    },
    {
        name: 'Lauren James',
        data: {
            title: 'I experienced a seamless onboarding process that allowed me to set up my online store within minutes.',
            commenttop: 'As a vendor on MamiMarkets.com, I cannot express enough how grateful I am for the opportunities and support this platform has provided me.',
        }
    },
    {
        name: 'William Andem',
        data: {
            title: 'I experienced a seamless onboarding process that allowed me to set up my online store within minutes.”',
            commenttop: 'As a vendor on MamiMarkets.com, I cannot express enough how grateful I am for the opportunities and support this platform has provided me.',
            commentdown: "But it doesn't stop there. MamiMarkets.com has truly opened doors for me to reach a vast customer base that I couldn't have accessed on my own. The platform's robust marketing features, such as product recommendations and targeted advertising, have significantly increased my brand visibility and attracted a steady stream of interested buyers. The enhanced market reach has allowed my small business to grow exponentially, and I've witnessed a remarkable increase in sales since joining the platform."
        }
    },

]
const vendors = [
    {
        img: person1,
        name: 'Adekunle',
        store: 'One Stop Meat Shop',
        location: 'Winnipeg, Manitoba',
        data: {
            title: ' one I experienced a seamless onboarding process that allowed me to set up my online store within minutes.',
            commenttop: 'As a vendor on MamiMarkets.com, I cannot express enough how grateful I am for the opportunities and support this platform has provided me.',
            commentdown: "But it doesn't stop there. MamiMarkets.com has truly opened doors for me to reach a vast customer base that I couldn't have accessed on my own. The platform's robust marketing features, such as product recommendations and targeted advertising, have significantly increased my brand visibility and attracted a steady stream of interested buyers. The enhanced market reach has allowed my small business to grow exponentially, and I've witnessed a remarkable increase in sales since joining the platform."
        }
    },
    {
        img: person2,
        name: 'Esther ',
        store: 'African Foodways Market',
        location: 'Winnipeg, Manitoba',
        data: {
            title: 'two  I experienced a seamless onboarding process that allowed me to set up my online store within minutes.',
            commenttop: 'As a vendor on MamiMarkets.com, I cannot express enough how grateful I am for the opportunities and support this platform has provided me.',
            commentdown: "But it doesn't stop there. MamiMarkets.com has truly opened doors for me to reach a vast customer base that I couldn't have accessed on my own. The platform's robust marketing features, such as product recommendations and targeted advertising, have significantly increased my brand visibility and attracted a steady stream of interested buyers. The enhanced market reach has allowed my small business to grow exponentially, and I've witnessed a remarkable increase in sales since joining the platform."
        }
    }, {
        img: person3,
        name: 'William ',
        store: 'Tasty Treats Market',
        location: 'Winnipeg, Manitoba',
        data: {
            title: 'thre I experienced a seamless onboarding process that allowed me to set up my online store within minutes.',
            commenttop: 'As a vendor on MamiMarkets.com, I cannot express enough how grateful I am for the opportunities and support this platform has provided me.',
            commentdown: "But it doesn't stop there. MamiMarkets.com has truly opened doors for me to reach a vast customer base that I couldn't have accessed on my own. The platform's robust marketing features, such as product recommendations and targeted advertising, have significantly increased my brand visibility and attracted a steady stream of interested buyers. The enhanced market reach has allowed my small business to grow exponentially, and I've witnessed a remarkable increase in sales since joining the platform."
        }
    },
]
export const Cards = () => {
    const [select, setSelect] = useState<number>(0);
    const [items, setItems] = useState<any>(vendors[0].data)
    const [item, setItem] = useState<Array<any>>(questions[0]?.data)
    const [show, setShow] = useState<number | null>(0)

    const screenResolution: any = useWindowSize();

    const handleSelect = (id: number) => {
        setSelect(id);

        setItems(comment[id]?.data)
    }
    const toggleAnswer = (index: number) => {
        if (show === index) {
            setShow(null)
        }
        else {
            setShow(index)
        }
    }

    const cards = [
        {
            bg: bg1,

            title: 'Market reach',
            text: `Access to a broader customer base`
        },
        {
            bg: bg2,

            title: 'Cost-effective setup',
            text: 'Budget-friendly solution with value'
        },
        {
            bg: bg3,

            title: 'Growth potential',
            text: 'Adapt to changing customer demands'
        },
        {
            bg: bg4,

            title: 'Store management',
            text: 'Efficiently run your online business operations'
        },

    ]

    return (
        <>
            <div className='cardContainer2'>


                <div className='top'>
                    <h3 className='title'>Seller Stories</h3>
                    {/* <h3 className='titles'>See what our current vendors<br /> are saying</h3> */}
                    <div className='rows'>
                        <div className='users'>
                            {
                                vendors.map((vendor, idx) => {
                                    return (
                                        <div key={idx} className={select === idx ? 'user' : 'user2'} onClick={() => {
                                            handleSelect(idx)
                                            if (screenResolution?.width >= 900) {
                                                setItems(vendor?.data)
                                            }
                                        }}>
                                            {screenResolution?.width <= 899 &&
                                                <div className="toggle-icon">
                                                    {select === idx && <span>-</span>}
                                                    {select !== idx && <span>+</span>}
                                                </div>
                                            }
                                            <img src={vendor.img} alt='' />
                                            <div className='details'>
                                                <h6 className='name'>{vendor.name}</h6>
                                                <p className='store'>{vendor.store}</p>
                                                <p className='store'>{vendor.location}</p>
                                            </div>

                                            {screenResolution?.width <= 899 && select === idx &&
                                                <div className='comments'>
                                                    <div>
                                                        <div className='quote'>
                                                            <BiSolidQuoteAltLeft size={55} color='#00A85B' />
                                                        </div>
                                                        <h3 className='commentTitle'>{vendor?.data.title}</h3>
                                                        <p className='commentText'>{vendor?.data.commenttop}</p>
                                                        {/* <p className='commentText'>{items.commentdown}</p> */}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }

                        </div>
                        {/* <div className='users-mobile'>
                            <Marquee>
                                {
                                    vendors.map((vendor, idx) => {
                                        return (
                                            <div key={idx} className={select === idx ? 'user' : 'user2'} onClick={() => handleSelect(idx)}>
                                                <img src={vendor.img} alt='' />
                                                <div className='details'>
                                                    <h6 className='name'>{vendor.name}</h6>
                                                    <p className='store'>{vendor.store}</p>
                                                    <p className='store'>{vendor.location}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Marquee>
                        </div> */}

                        {/* <div className='quote'>
                            <BiSolidQuoteAltLeft size={80} color='#E7FAE8' />
                        </div> */}
                        {screenResolution?.width >= 900 &&
                            <div className='comments'>
                                <div>
                                    <div className='quote'>
                                        <BiSolidQuoteAltLeft size={55} color='#12B76A' />
                                    </div>
                                    <h3 className='commentTitle'>{items.title}</h3>
                                    <p className='commentText'>{items.commenttop}</p>
                                    {/* <p className='commentText'>{items.commentdown}</p> */}
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="faq-wrap">
                    <h3 className='title'>Frequently Asked Questions</h3>
                    <div className="contact-cta">
                        <Link className="btn" to="/get-started"> Contact Us</Link>
                    </div>
                    {/* <h3 className='titles'>Frequently Asked Questions</h3> */}
                    <div className='top2'>

                        <div className='right'>
                            {item?.map((question, index) => (
                                <div key={index}>
                                    <div className="question">
                                        <div className="flexes" onClick={() => toggleAnswer(index)}>
                                            <h3 className="question">{question.question}</h3>
                                            <div >
                                                <LiaAngleDownSolid size={14} />
                                            </div>
                                        </div>
                                        {
                                            show === index && <p className="answer">{question.answer}</p>
                                        }
                                    </div>
                                    {index< item.length-1 &&  <div className="bottom" />}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            </div>
            <div className='mapBg2'>
                <div className="joinus-wrap">

                    <p className='join'>JOIN US TODAY</p>
                    <h3 className='mapTitle'>Discover why 100+ businesses are partnered with MamiMarkets </h3>
                    <p className='mapText'>From our proven track record to our tailored solutions, industry insights,
                        extensive network, and unwavering commitment
                        to customer satisfaction, MamiMarkets is the trusted partner
                        you need to accelerate your business growth and achieve remarkable results.
                    </p>
                    <Link className="btn join-cta" to="/get-started"> Create a seller account</Link>
                    {/* <div className="join-arrow"></div> */}
                    {/* <button className='start'>
                    </button> */}
                </div>
            </div>
        </>
    )
}
